import { baseUrl } from "../Constants";
import { makeDate } from "helpers/utils";

export const smsTypeList = [
  {key:0, value:'NONE'},
  {key:1, value:'SOIL TESTING RESULTS'},
  {key:2, value:'CROP ADVISORY SERVICE'},
  {key:3, value:'CROP ADVISORY OPENING'},
  {key:4, value:'UPON SUCCESSFUL PAYMENT'},
];

export const smsTemplateTableHeaders = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "smsType",
    numeric: false,
    disablePadding: true,
    label: "Sms Type",
  },
  { id: "content", numeric: false, disablePadding: true, label: "Content" },
  {
    id: "sentAfterDays",
    numeric: true,
    disablePadding: true,
    label: "Sent After Days",
  },
  { id: "languageId", numeric: false, disablePadding: true, label: "Language" },
  { id: "cropId", numeric: false, disablePadding: true, label: "Crop" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Date Updated",
  },
];

export const smsTemplateTableSchema = {
  id: { value: "", type: "number", label: "ID", readOnly: true },
  smsType: { value: "", type: "text", label: "SMS Type" },
  content: { value: "", type: "text", label: "Content" },
  sentAfterDays: { value: "", type: "text", label: "Sent After Days" },
  languageId: { value: "", type: "text", label: "Language" },
  cropId: { value: "", type: "text", label: "Crop" },
  createdAt: {
    value: "",
    type: "text",
    label: "Date Created",
    readOnly: true,
  },
  updatedAt: {
    value: "",
    type: "text",
    label: "Date Updated",
    readOnly: true,
  },
};
export const createSmsTemplateRecord = (data) => {
  let record = {
    id: { value: data.id, type: "number", label: "ID", readOnly: true },
    smsType: { value: data.smsType, type: "text", label: "SMS Type" },
    content: { value: data.content, type: "text", label: "Content" },
    sentAfterDays: { value: data.sentAfterDays, type: "text", label: "Senter After Days" },
    languageId: { 
      value: data.language.id, 
      type: "text", 
      label: 'Language',
      languageName: (data.language == undefined ? "" : data.language.name),
    },
    cropId: { 
        value: data.crop.id, 
        type: "text", 
        label: 'Crop',
        cropName: (data.crop == undefined ? "" : data.crop.name), 
      },
    createdAt: {
      value: makeDate(data.createdAt),
      type: "text",
      label: "Date Created",
      readOnly: true,
    },
    updatedAt: {
      value: makeDate(data.updatedAt),
      type: "text",
      label: "Date Updated",
      readOnly: true,
    },
  };
  return record;
};

export const smsTemplateTableIdField = "id";
export const smsTemplateTableTitle = "SMS Template";
export const smsTemplateBaseUrl = baseUrl + "smstemplate";
