import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import TablePagination from "@material-ui/core/TablePagination";
import { ErrorAlert } from "components/Alerts";
import { useAuth } from "../../helpers/use-auth";
const useStyles = makeStyles(styles);
// core components
import TransactionsTable from "./TransactionsTable";
import { baseUrl } from "helpers/Constants";
import {
  paymentTableSchema,
  paymentTableHeaders,
  paymentBaseUrl,
  paymentTableTitle,
  paymentTableIdField,
  createPaymentRecord,
} from "helpers/schemas/Payment";
import CartItems from "glite-components/shop/CartItems";

export default function TransactionsTableWrapper({ userId }) {
  const auth = useAuth();
  const activeUserId = userId ? userId : auth.user.id;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartView, setCartView] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState(undefined);
  const [downloading, setDownloading] = useState(false);
  const pageSize = 15;
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(pageSize);
  useEffect(() => {
    let paymentUrl = `${paymentBaseUrl}/${activeUserId}?page=${page}&size=${
      rowsToFetch < 1 ? pageSize : rowsToFetch
    }`;
    setError(undefined);
    setLoading(true);
    axios
      .get(paymentUrl)
      .then((response) => {
        setData(response.data.elements.map((row) => createPaymentRecord(row)));
        setLoading(false);
        setError(undefined);
        setEmptyRows(pageSize - rowsToFetch);
        setRowsToFetch(Math.min(pageSize, response.data.total - pageSize));
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [page]);
  function paginate(pageNum) {
    if (pageNum < page) {
      setRowsToFetch(pageSize);
    }
    setPage(pageNum);
  }
  function handleDownload(fromDate, toDate) {
    setError(undefined);
    setDownloading(true);
    axios({
      url: `${baseUrl}payments/download?from=${fromDate}&to=${toDate}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payment_report.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setDownloading(false);
      });
    console.log("xxxx download date range ", fromDate, toDate);
  }
  function handleLoadOrder(data) {
    setError(undefined);
    axios
      .get(`${baseUrl}orders/byTransaction/${data.id.value}`)
      .then((response) => {
        // setLoading(false);
        let items = response.data.items.map((item) => {
          return {
            desc: `${item.product.name} ${item.product.description}: ${item.paymentReference}`,
            supplier:
              item.product.supplier.firstName + item.product.supplier.lastName,
            qty: item.qty,
            unitPrice: item.unitPrice,
            unit: item.product.unit.label,
          };
        });
        setCartItems(items);
        setError(undefined);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setError(error.msg);
        // setLoading(false);
      });

    setCartView(true);
  }
  function handleBack() {
    setCartView(false);
  }
  return cartView ? (
    <div>
      <Tooltip title="Back to edit">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      {error && <ErrorAlert msg={error} />}
      <CartItems items={cartItems} />
    </div>
  ) : (
    <>
      <TransactionsTable
        classes={classes}
        tableSchema={paymentTableSchema}
        tableHeaders={paymentTableHeaders}
        tableTitle={paymentTableTitle}
        idField={paymentTableIdField}
        onLoadOrder={handleLoadOrder}
        loading={loading}
        data={data}
        onDownload={handleDownload}
        downloading={downloading}
        emptyRows={emptyRows}
      />
      <TablePagination
        component="div"
        count={-1}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(event, newPage) => paginate(newPage)}
      />
    </>
  );
}
TransactionsTableWrapper.propTypes = { userId: PropTypes.number };
